<script lang="ts" setup>
const { duoTone } = useBranding();

// #region Props
defineProps({
   hasTasks: { type: Boolean, default: false },
});
// #endregion
</script>

<template>
   <LayoutCard :class="'no-planning-card'">
      <p v-if="hasTasks">
         {{ $t('my-week.finish-todos') }}
      </p>
      <template v-else>
         <p>{{ $t('my-week.no-planning') }}</p>

         <NuxtLink
            class="no-planning-card__link"
            :to="'/schedule'"
            :style="{ color: duoTone ? 'var(--nxt-secondary)' : 'var(--nxt-main)' }"
         >
            <i class="fas fa-calendar-days"></i>
            {{ $t('my-week.view-your-schedule') }}
         </NuxtLink>
      </template>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.no-planning-card {
   width: 100%;
   width: 100%;
   padding: var(--nxt-gutter);
   display: flex;
   flex-direction: column;
   text-align: start;
   gap: var(--nxt-gutter);

   p {
      margin: 0;
      color: var(--nxt-medium-grey);
   }

   button {
      margin-left: var(--nxt-gutter-small--negative);
   }
}

.no-planning-card__link {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
   margin-top: var(--nxt-gutter-small);
}
</style>
